<template>
  <g>
    <svg:style>
      .cls-4, .cls-5, .cls-6 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4 {
        fill: url(#glass-pattern);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }

      .cls-6 {
        fill: url(#inox-gradient);
      }
    </svg:style>
    <linearGradient
      id="handle-gradient"
      :x1="doorLeftWidth1 + 9.95"
      :y1="doorTopHeight1 + 147.88"
      :x2="doorLeftWidth1 + 15.54"
      :y2="doorTopHeight1 + 147.88"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#97999d"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a7aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient
      id="inox-gradient"
      :x1="inoxX"
      :y1="glassTY + 53.98"
      :x2="inoxX + inoxW"
      :y2="glassTY + 53.98 * 2"
      xlink:href="#handle-gradient"/>
    <g id="V11">
      <rect
        id="glass"
        class="cls-4"
        :x="glassX"
        :y="glassTY"
        width="12.65"
        height="53.98"/>
      <rect
        id="glass-2"
        data-name="glass"
        class="cls-5"
        :x="glassX"
        :y="glassTY + 53.98*2"
        width="12.65"
        height="53.98"/>
      <rect
        id="inox"
        v-if="showInox"
        class="cls-6"
        :x="glassX"
        :y="glassTY + 53.98"
        width="12.76"
        height="54.97"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      glassWidth: 12.65,
      glassHeight: 162.93,
    }
  },
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
    glassX() {
      return this.doorLeftWidth + (this.doorWidth - this.glassWidth)/2
    },
    glassTY() {
      return this.doorTopHeight + (this.doorHeight - this.glassHeight)/2
    },
  }
}
</script>
